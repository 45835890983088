import React, { useState } from 'react';
import ReactPlayer from "react-player/youtube";

function App() {
  return (
    <div class="container">
      <ReactPlayer
        url="https://youtu.be/O96W1xkI6jo"
        width="100%"
        height="300px"
      />
      <h1>Jaz Gulati</h1>
      <p>Updated <time datetime="2024-08-30">August 30, 2024</time></p>
      <h2>About me:</h2>
      <ul>
        <li>Enjoyer of sounds</li>
        <li>Founder of <a href="https://grassroots.studio">Grassroots Studio</a>, a software company
          <ul>
            <li>Now: Making Web3 friendly at <a href="https://catalog.fi">Catalog</a> and <a href="https://garden.finance">Garden</a></li>
            <li>Past: Worked on <a href="https://renprotocol.org">Ren</a> (acquired by <Tooltip text="FTX" tooltipText="🥴" />), a protocol that enables the movement of value between blockchains</li>
            <li>Past: Built a wagering platform with <Tooltip text="3AC" tooltipText="😵‍💫" /></li>
          </ul>
        </li>
      </ul>
      <h2>Current rotation:</h2>
      <ul>
        <li>Gucci Mane - I'm Up <span>(Trap, 2012)</span></li>
        <li>Koopsta Knicca - Da Devil's Playground <span>(Memphis Rap, 1999)</span></li>
        <li>corto.alto - 30​&#47;​108 <span>(Jazz Fusion, 2024)</span></li>
        <li>DJ Screw - The Legend <span>(Chopped and Screwed, 2001)</span></li>
        <li>Tommy Wright III - Ashes 2 Ashes, Dust 2 Dust<span>(Memphis Rap, 1994)</span></li>
        <li>NxWorries - Why Lawd? <span>(Funk Rap, 2024)</span></li>
        <li>Navy Blue - Memoirs In Armour<span>(Abstract Hip Hop, 2024)</span></li>
        <li>Chief Keef - The Leek, Vol. 1<span>(Chicago Drill, 2015)</span></li>
        <li>454 - Fast Trax 3 <span>(Cosmic Rap, 2022)</span></li>
        <li>Doja Cat - Scarlet 2 CLAUDE <span>(Pop Rap, 2024)</span></li>
      </ul>
    </div>
  );
}

const Tooltip = ({ text, tooltipText }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  const handleMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <div
      className="tooltipText"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      {text}
      {visible && (
        <div
          className="tooltip"
          style={{ top: position.y, left: position.x, transform: 'translate(-50%, -120%)' }}
        >
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default App;
